/* eslint-disable import/no-cycle */
import Flex from "app/components/Flex";
import { useGetMenuOption } from "app/routes/Library/hooks";
import React from "react";
import { useHistory } from "react-router-dom";
import { NavFilterButton } from "app/components/Button/NavFilterButton";
import { useUserAccessType } from "hooks/Subscriptions/useUserAccessType";
import { AccessType } from "services/graphql";
import { accessSync } from "fs";
import { MenuOptions } from "./types";

const freeUserMenuOptions = [
  {
    text: "all classes",
    value: MenuOptions.CLASSES,
  },
  {
    text: "free classes",
    value: MenuOptions.FREE_CLASSES,
  },
  {
    text: "programs",
    value: MenuOptions.PROGRAMS,
  },
  {
    text: "categories",
    value: MenuOptions.CATEGORIES,
  },
];

const basicUserMenuOptions = [
  {
    text: "all classes",
    value: MenuOptions.CLASSES,
  },
  {
    text: "basic classes",
    value: MenuOptions.BASIC_CLASSES,
  },
  {
    text: "programs",
    value: MenuOptions.PROGRAMS,
  },
  {
    text: "categories",
    value: MenuOptions.CATEGORIES,
  },
];

const standardUserMenuOptions = [
  {
    text: "classes",
    value: MenuOptions.CLASSES,
  },
  {
    text: "programs",
    value: MenuOptions.PROGRAMS,
  },
  {
    text: "categories",
    value: MenuOptions.CATEGORIES,
  },
  {
    text: "instructors",
    value: MenuOptions.INSTRUCTORS,
  },
];

const accessTypeMenuOptions: any = {
  [AccessType.Free]: freeUserMenuOptions,
  [AccessType.Basic]: basicUserMenuOptions,
  [AccessType.Standard]: standardUserMenuOptions,
};

interface MenuProps {
  text: string;
  value: MenuOptions;
}

export const Menu = () => {
  const userAccessType = useUserAccessType();
  const { selectedOption } = useGetMenuOption();
  const history = useHistory();

  const checkSelectedOption = (option: MenuOptions) =>
    option === selectedOption;

  const handleSelectOption = (option: MenuOptions) => {
    history.push(`/library/${option}`);
  };

  const menuOptions = accessTypeMenuOptions[userAccessType];

  return (
    <Flex alignItems="center" width="100%" flexWrap="wrap" gap="8px">
      {menuOptions.map((option: MenuProps) => (
        <NavFilterButton
          text={option.text}
          isSelected={checkSelectedOption(option.value)}
          onClick={() => handleSelectOption(option.value)}
        />
      ))}
    </Flex>
  );
};
