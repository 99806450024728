import Flex from "app/components/Flex";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { FilterIconButton } from "app/components/Button/IconButton/FilterIconButton";
import { AlgoliaIndexes } from "app/components/Algolia/types";
import { useGetMenuOption } from "../hooks";
import { Menu } from "./Menu";
import { Title } from "./styles";

const filterButtonIndexes = {
  classes: AlgoliaIndexes.classes,
  "free-classes": AlgoliaIndexes.classes,
  "basic-classes": AlgoliaIndexes.classes,
  programs: AlgoliaIndexes.programs,
};

export function Header() {
  const { selectedOption } = useGetMenuOption();

  const showFilter = Object.keys(filterButtonIndexes).includes(selectedOption);
  const indexName = showFilter ? filterButtonIndexes[selectedOption] : "";

  const isSmallScreen = useMediaQuery({
    maxDeviceWidth: "1044px",
  });

  return (
    <Flex flexDirection="column">
      <Flex alignItems="center" mb="12px">
        <Title>Library</Title>
        {!isSmallScreen && <Menu />}
        {showFilter && (
          <Flex justifyContent="center" alignItems="center" marginLeft="auto">
            <FilterIconButton indexName={indexName} />
          </Flex>
        )}
      </Flex>
      {isSmallScreen && (
        <Flex mt="12px">
          <Menu />
        </Flex>
      )}
    </Flex>
  );
}
